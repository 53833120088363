
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex-grow: 1;
  padding-bottom: 0px; /* Adjust this value to match your footer height */
}
